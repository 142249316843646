//Code formatting (used now for pages numbers+)
pre,
code {
    font-size: 15px;
    border: 1px solid $color_grey--light;
    border-radius: 3px;
    background-color: #eef;
    text-align: center;
    p & {
      background-color: transparentize($brand-color, 1);
      border: 0 none;
      border-radius: 0;
      padding: .75rem;
      display: block;
      color: transparentize($brand-color, .5);
    }
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}
