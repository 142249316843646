nav {
  background-color: $menu_bg_color;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  box-sizing: border-box;
  width: 100%;
  height: $nav_height;
  padding-top: $spacing_unit*4;
  overflow-x: hidden;
  overflow-y: scroll;
  //-webkit-transition: all .2s ease-in;

  .menu_link {//a (mostly, well last1 is a div)
    color: white;
    display: block;
    position: relative;
    margin-left: .5rem;
    margin-right: .5rem;
    padding: .5rem 4rem .5rem .5rem;
    @include media( "screen", ">bp1Width") {
      padding: .5rem 3rem .5rem 3rem;
    }
    @include media( "screen", ">maxWidth") {
      padding: .5rem 20vw .5rem 20vw;
    }
    border: 0 none;

    svg {
      max-width: 180px;
      max-height: 180px;
      position: relative;
      left: 0;
      top: .5rem;
    }
  }

  .menu_link:visited span{ margin-left: .5rem; }

  .menu_link:hover {
    border:0 none;
    color: rgba(255,255,255,.75);
  }

  .menu_link:active,
  .menu_link.active {
    color: invert($brand_color);
    border: 0 none;
  }


  // .menu_link:first-child {
  //   margin-top: $spacing_unit*4;
  // }
  .menu_link:last-child { margin-bottom: $spacing_unit; }

  small {
    display: none;
    @include media( "screen", ">bp1Width") {
      min-width: 30px;
      padding-right: .5rem;
      margin-right: 1rem;
      margin-left: -30px;
      display: block;
      float: left;
      height: 100%;
      position: absolute;
      font-size: 20px;
      line-height: 25.2px;
      opacity: .3;
    }
  }
}
