// SPA content
.page-content {
    overflow-x: hidden;
    padding: 0 0 0 0;

    .addon.yellow {
      background-color: lighten($color_yellow, 40%);
    }
    .addon.green {
      background-color: lighten($color_green, 40%);
    }
    .addon.red {
      background-color: lighten($color_red, 40%);
    }
    .addon.brown {
      background-color: lighten($color_brown, 60%);
    }
    .addon.blue {
      background-color: lighten($brand_color, 57%);
    }
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing_unit;
    }
}

.post-meta {
    font-size: $small_font-size;
    color: $color_grey;
    display: none;
}

.post-link {
    display: block;
    font-size: 24px;
}

#contents .site_logo {
  display: none;
}
