//toc

//variable for left padding / margin of ToC
$leftma: 4vw;
$leftpa: 8px;
$botpa: 10px;
$toppa: 10px;

#markdown-toc ul,
#markdown-toc {
  list-style: none;
  li {
    margin-top: $toppa;
    margin-bottom: $botpa;
  }
  li:first-letter {
    text-transform: uppercase;
  }
}

#markdown-toc {
  margin-left: 0;
  margin-bottom: $spacing_unit*4;
  padding-left: $leftma/2;
  padding-right: $leftma/2;
  border: 4px solid transparentize($brand_color, .9);
  #green-pages & {
    border: 4px solid  transparentize($color_green, .15);
  }
  .addon.brown & {
    border: 4px solid  transparentize($color_brown, .25);
  }
}

#markdown-toc ul {
  margin-left: $leftma;
  li {
    margin-left: -$leftma;
    padding-left: $leftma/2;
    border-left: 4px solid transparentize($brand_color, .85);
    #green-pages & {
      border-left: 4px solid  transparentize($color_green, .15);
    }
    .addon.brown & {
      border-left: 4px solid  transparentize($color_brown, .25);
    }
  }
  ul li {
    border-left: 4px solid transparentize($brand_color, .8);
    #green-pages & {
      border-left: 4px solid  transparentize($color_green, .15);
    }
    .addon.brown & {
      border-left: 4px solid  transparentize($color_brown, .25);
    }
  }
}


a#markdown-toc-a,
a#markdown-toc-b,
a#markdown-toc-c,
a#markdown-toc-d,
a#markdown-toc-e,
a#markdown-toc-f,
a#markdown-toc-g,
a#markdown-toc-h,
a#markdown-toc-i,
a#markdown-toc-j,
a#markdown-toc-k,
a#markdown-toc-l,
a#markdown-toc-m,
a#markdown-toc-n,
a#markdown-toc-o,
a#markdown-toc-p,
a#markdown-toc-q,
a#markdown-toc-r,
a#markdown-toc-s,
a#markdown-toc-t,
a#markdown-toc-u,
a#markdown-toc-v,
a#markdown-toc-w,
a#markdown-toc-x,
a#markdown-toc-y,
a#markdown-toc-z {
  width: 40px;
  height: 40px;
  float: left;
  border: 2px solid transparentize($color_green, .15);
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 40px;
  margin: 10px;
  &:hover {background-color: transparentize($color_green, .15);}
}
a#markdown-toc-information-on-medicines {
  clear: both;
  display: block;
  padding-top: 1rem;
}

.yellow {

  [href*=-subjects] {
    width: 40px;
    height: 40px;
    float: left;
    border: 2px solid transparentize($color_yellow, .15);
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 40px;
    margin: 10px;
    &:hover {
      background-color: transparentize($color_yellow, .15);
    }
  }

  [href*=z-subjects]{clear: right;}

  em:first-child {
    display: block;
    clear: both;
    width: 100%;
  }

  h5#note {
    clear: both;
    display: block;
    padding-top: 1rem;
  }
}


//not really a toc but yes the use is the same
.red .vocabulary_alphabet_links {
  width: 100%;
  a {
    width: 40px;
    height: 40px;
    float: left;
    color: $color_red;
    border: 2px solid transparentize($color_red, .15);
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 40px;
    margin: 10px;
    &:hover {
      background-color: transparentize($color_red, .15);
      color: white;
    }
  }
}

.red h1 { padding-top: $spacing_unit*2;}
