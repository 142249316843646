
.post-header {
    margin-bottom: $spacing_unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media( "screen", ">maxWidth") {
        font-size: 36px;
    }
}

.post-content {
  margin-bottom: $spacing_unit;

}

table {
  width: 100%;
  td {
    vertical-align: top;
    padding: .5rem 0;
  }
  td:first-child {
    padding-right: .5rem;
  }
  td:last-child {
    padding-left: .5rem;
    text-align: right;
  }
  .chapter_content & {
    th {
      text-align: left;
    }
    th:first-child {
      padding-right: .5rem;
    }
    th:last-child {
      padding-left: .5rem;
      text-align: right;
    }
  }
  a {
    //display: block;
    min-height: 48px;
  }
}

table.grid {
  tr {
    border-bottom: 1px solid rgba(0,0,0,.25);
  }
}
