
.wrapper {
    margin-right: auto;
    margin-left: auto;
    padding-left: $spacing_unit;
    padding-right: $spacing_unit;
    @include media( "screen", ">minWidth") {
      padding-left: $spacing_unit*1.25;
      padding-right: $spacing_unit*1.25;
    }
    @include media( "screen", ">bp1Width") {
      padding-left: $spacing_unit*1.5;
      padding-right: $spacing_unit*1.5;
    }
    @include media( "screen", ">maxWidth") {
      padding-left: $spacing_unit*1.75;
      padding-right: $spacing_unit*1.75;
    }

    @extend %clearfix;
    max-width: 650px;

    .page-content & {padding-top:0;}
    .chapter_title & {position:relative;}
}
