//cover styles
.cover {
  background-color:  $background_color_tone;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.site_logo{ //a
  display: block;
  width: 100%;
  position: relative;
  z-index: 10;
  border-bottom: 0 none;
  &:hover {border-bottom: 0 none;}
}

.site_logo_svg--cover {
  display: block;
  width:100%;
  min-height: 50vh;
  padding-top: $spacing_unit/2;
  padding-bottom: $spacing_unit/2;
}
