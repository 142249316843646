
.menu_toggle {//buttons container
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 20;
  cursor: pointer;
  width: 48px;
  height: 48px;
  text-align: center;
  background-color: $background_color_tone;
  border-radius: 5px;
}
.menu_ico, .close_ico {//buttons
  color: black;
  &:hover { color: white;}
  > svg {
    padding: 2px;
    display: inline-block;
    width: 44px;
    height: 44px;
  }
}
