.site-footer {
  border-top: 1px solid $color_grey--light;
  padding: $spacing_unit 0;
  background-color: $text_color;
  color: $color_grey--light;
  h2 {
    color: $background-color;
  }
  a:hover {
    color: lighten($brand_color, 20%);
    font-weight: 300;
  }
  a:visited {
    color: white;
  }
  li {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  strong {
    font-weight: normal;
    display: block;
    color: lighten($brand_color, 20%);
  }
  small {
    display: block;
  }
  span:not(.icon):not(.username) {
    display: block;
    @include media( "screen", ">bp1Width") {
      display: inline;
    }
  }
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing_unit / 2;
}

.contact_list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}
.circlelogofooter {
  display: block;
}
