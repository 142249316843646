main {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 100%;
  z-index:1;
}

.chapter {
  padding-bottom: $spacing_unit*2;
  margin-bottom: $spacing_unit*4;
  position: relative;
}

.chapter_no {
  pointer-events: none;
  color: rgba(255,255,255,.5);
  font-size: 30rem;
  position: absolute;
  top: -115px;
  right: 10px;
  font-weight: $light_font_weight;
  line-height: 1;
  text-align: right;
  z-index: 0;
  letter-spacing: -3rem;
}


.addon {
  &.green {
    background-color: lighten($color_green, 40%);
  }
  &.yellow {
    background-color: lighten($color_yellow, 40%);
  }
  &.red {
    background-color: lighten($color_red, 40%);
  }
  &.brown {
    background-color: lighten($color_brown, 55%);
  }
  &.grey {
    background-color: lighten($color_grey, 50%);
  }
  &.blue {
    background-color: lighten($brand_color, 55%);
  }
}
.chapter_title {
  min-height: 300px;
  overflow: hidden;
  background-color: transparentize(lighten($brand_color, 40%), .5);

  h1 {
    text-transform: uppercase;
    position: relative;
    z-index: 1;
  }

  small { color: $background_color; }

  .yellow & { background-color: $color_yellow; }
  .green & { background-color: $color_green; }
  .brown & { background-color: $color_brown; }
  .red & { background-color: lighten($color_red, 20%); }
  .grey & { background-color: lighten($color_grey--light, 20%); }
  .blue & { background-color: lighten($brand_color, 50%); }
}


#contents .chapter_title {
  min-height: 0;
  height: 60px;
}
.chapter_content {
  margin-top: $spacing_unit*2;
  padding-bottom:  $spacing_unit*2;
}

.site_logo_svg--chapter {
  margin-top: $spacing_unit;
  max-width: 150px;
}

.yellow p {
  font-size: 110%;
  a {
    font-size: 110%;
  }
}


#vocabulary a { color: $color_red_links;}

#green-pages a { color: $color_green_links;}

#yellow-pages {

  :not(.chapter_title) h1:not(.no_toc):not(:first-child) {
    margin-top: $spacing_unit*3;
    padding-bottom: 5px;
    border-bottom: 3px solid $color_yellow_links;
  }
  .chapter_title h1{
    border-bottom: 0 none!important;
  }
  a { color: $color_yellow_links;}
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: .5rem;
    border-left: 3px solid $color_yellow_links;
  }
  li {}
}


.addon.brown a,
.addon.brown h1.no_toc a { color: $color_brown_links;}

.addon.blue a { color: $color_blue_links;}

.addon.grey a { color: $color_grey_links;}
