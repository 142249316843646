//page numbers
h1[id*="page-"] {
  font-size: 2rem;
  //font-weight: 100;
  line-height: .9;
  background-color: transparent;
  text-align: center;
  text-transform: none;
  color: white;

  padding: 0;
  margin-top: 4rem;
  margin-bottom: 1rem;

  position: relative;
  top: 0;
  left: 0;
  z-index: 1;

  &:after {
    content: "";
    background-color: rgba(0,0,0,0.1);
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 50%;
    position: relative;
    left: calc(50% - 35px);
    top: -50px;
    z-index: -1;
  }
}

h1 a[href*="page-"] {
  color: white;
  pointer-events: none;
}

.addon .chapter_page_anchor,
.chapter .chapter_page_anchor {//h1
  top: 10px;
  padding-top: 0!important;
  margin-top: 0!important;
  max-height: 4rem;
  position: relative;
  &:after {
    display: none;
  }
}
