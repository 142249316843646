@charset "utf-8";

// variables
$base_rem_size: 18px;
$base_font_size:  1rem;
$base_font_size_big: 20px;

$base_font_weight: 300;
$light_font_weight: 100;
$bold_font_weight: 500;

$base_line_height: 1.4;
// derivates
  $small_font-size:  $base_font_size * 0.875;
  $h1_font_size: $base_font_size*1.4;
  $h2_font_size: $base_font_size*1.333;
  $h3_font_size: $base_font_size*1.275;
  $h4_font_size: $base_font_size*1.2;
  $h5_font_size: $base_font_size*1.15;
  $h6_font_size: $base_font_size*1.05;

$spacing_unit: 30px;
//colors
$background_color: darken(#fff, 10%);
$text_color: #000;
$brand_color: hsla(213, 95%, 39%, 1);
$color_yellow: hsla(56, 100%, 50%, 0.75);
$color_green: hsla(115, 100%, 50%, 0.5);
$color_red: red;
$color_brown: hsla(29, 71%, 23%, 0.5);
$color_grey: #333333;
// links colors
$color_green_links: darken($color_green, 30%);
$color_red_links: darken($color_red, 20%);
$color_yellow_links: darken($color_yellow, 20%);
$color_brown_links: hsla(29, 71%, 40%, 1);
$color_grey_links: $color_grey;
$color_blue_links: darken($brand_color, 10%);



// derivs
 $color_grey--light: lighten($color_grey, 40%);
 $color_grey--dark:  darken($color_grey, 25%);
 $background_color_tone: lighten($brand_color, 35%);

//menu
$menu_bg_color: $brand_color;
$nav_height: 100vh;

// target needs CRAPPY PHONES Break-points here:
$mediaMinWidth: 320px;
$mediaBp1Width: 480px;
$mediaMaxWidth: 960px;


// Import partials from `_sass`
@import
 "cmq",
 "base",
 "toc",
 "helpers",
 "svgs",
 "code",
 "headings",
 "wrapper",
 "cover",
 "chapter",
 "nav",
 "page",
 "post",
 "index",
 "footer",
 "nav_toggle",
 "menu_footer",
 "powersaving",
 "print"
;
