
.bandaid_color {
  fill: $background_color;
}

.bandaid_color--invert {
  fill: $text_color;
}

.site_logo_svg--footer {
  width: 140px;
  height: 140px;
}

#icn--circlelogomenu {
  .bandaid_color {
    fill: $background_color_tone;
  }
}

.qr .svg_qr {
  margin-left: 0;
  max-width: 150px;
  background-color: white;
}
