
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/*Helper classes*/
.center {text-align: center;}
.inline {display: inline-block;}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.group:before,
.group:after {
    content: " ";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    *zoom: 1;
}


.spacer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.center.spacer {
  color: $color_grey--light;
  &:before {
    content: "________";
    position: relative;
    top: -8px;
    padding-right: 1rem;
    color: $color_grey--light;
  }
  &:after {
    content: "________";
    position: relative;
    top: -8px;
    padding-left: 1rem;
    color: $color_grey--light;
  }
}
