//coveralls
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing_unit / 2;
}


h1, h2, h3, h4, h5, h6 {
    font-weight: $base_font_weight;
    text-transform: capitalize;
    margin-top: $spacing_unit/ 2;
}


html {

  font: caption;

  .ios & { // if IOS use:
    // Used as a fallback
    font: sans-serif;
    font-family: "-apple-system", "HelveticaNeue", sans-serif; }

  font-size: $base_rem_size;
  line-height: $base_line_height;

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  background-color: $text_color;
}


body {
  font-size: $base_font_size;
  @include media( "screen", ">bp1Width") {
      font-size: $base_font_size_big;
  }

  margin: 0;

  font-weight: $base_font_weight;
  line-height: $base_line_height;

  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;

  background-color: $background_color;
  color: $text_color;

  overflow-x: hidden;
}


h1 { font-size: $h1_font_size; line-height: 1.25;}
h2 { font-size: $h2_font_size; line-height: 1.35;}
h3 { font-size: $h3_font_size; line-height: 1.45;}
h4 { font-size: $h4_font_size; }
h5 { font-size: $h5_font_size; }
h6 { font-size: $h6_font_size; }


::-moz-selection {
    background: $brand_color;
    text-shadow: none;
}

::selection {
    background: $brand_color;
    text-shadow: none;
}


fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2rem 0;
    background: $brand_color;
    color: #000;
    padding: 0.2rem 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}



abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: $bold_font_weight;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
  line-height: $base_rem_size;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5rem;
}

sub {
  bottom: -0.25rem;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1rem 40px;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 0;
  height: 1px;
  background: #000;
  margin-bottom: $spacing_unit;
    // background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}


button[disabled],
html input[disabled] {cursor: default;}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {line-height: normal;}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


legend {
  border: 0;
  padding: 0;
}

textarea {overflow: auto;}

optgroup {font-weight: $bold_font_weight;}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {padding: 0;}


audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}


img {
  max-width: 100%;
  vertical-align: middle;
}

figure > img {
  display: block;
}

figcaption {
  font-size: $small_font-size;
}

ul, ol {
  margin-left: $spacing_unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



a {
  padding-top: 1px;
  padding-bottom: 1px;
  color: $brand_color;
  text-decoration: none;
  background-color: transparent;
  border-bottom: 5px solid rgba(0,0,0,0);
  p & {border-bottom: 5px solid rgba(0,0,0,.05);}
  //compensate for the border
  margin-top: 5px;

  &:hover,
  &:active {
    outline: 0;
    color: $text_color;
    text-decoration: none;
    border-bottom: 5px solid rgba(0,0,0,.15);
  }

  &:visited {
    color: darken($brand_color, 15%);
  }
  &:focus {
    outline: 1px solid black;
    background-color: black;
    color: white!important;
  }
}


blockquote {
  color: $color_grey;
  border-left: 4px solid $color_grey--light;
  padding-left: $spacing_unit/2;
  padding-right: $spacing_unit/2;
  font-size: $h3_font_size;
  font-weight: $base_font_weight;
  letter-spacing: -1px;
  font-style: italic;
  @include media( "screen", ">minWidth") {
    margin-left: $spacing_unit;
    margin-right: $spacing_unit;
  }

  > :last-child {
    margin-bottom: 0;
  }
}


.hide {display: none;}

.icon {

  > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
      fill: $color_grey;
    }
  }
}
